<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frm')" data-vv-scope="frm" novalidate="novalidate">
    <div class="row justify-content-md-center">

      <!-- Tipo de Disparador -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('taskCampaign.tipoDisparador') }}</h5>
            <div class="row">
              <advanced-search
                emptyMessage="Nenhum tipo de disparador selecionado"
                :title=null
                name="restricao.idtipoDisparador"
                labelField="nome"
                v-validate="{ required: true }"
                :error="errors.first('restricao.idtipoDisparador')"
                rootClassName="col-sm-10 row"
                @onSelect="setTipoDisparador"
                :value="restricao.idtipoDisparador"
                :clearable="false"
                :options="tipoDisparadorList"
                v-bind:fetchOptions="tipoDisparadorFetchOptions"

              >
              </advanced-search>
            </div>
          </div>
        </div>
      </div>

      <!-- Cliente -->
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.task-campaign-delivery-restriction.customers') }}</h5>
            <advanced-multiple-select :customLabel="customerCustomLabel"  @onRemoved="customerRemoved" @onInput="customerSelected" @rowClicked="customerSelected"   :enableStatus="1"  :value="restricao.restricaoClientes" :options="customerList" id-field="id"  labelField="name" :no-options="'Buscar Cliente'" :tableFields="clientesFields" v-bind:fetchOptions="customerFetchOptions"></advanced-multiple-select>
          </div>
        </div>
      </div>

      <!-- Contatos -->
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.task-campaign-delivery-restriction.contacts') }}</h5>
            <advanced-multiple-select :customLabel="contactCustomLabel"  @onRemoved="contactRemoved" @onInput="contactSelected" @rowClicked="contactSelected"   :enableStatus="1"  :value="restricao.restricaoContatos" :options="contactList" id-field="id"  labelField="label" :no-options="'Buscar Contatos'" :tableFields="contatosFields" v-bind:fetchOptions="contactFetchOptions"></advanced-multiple-select>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn-outline-light  pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>

    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import AdvancedSearch from '@/components/Forms/AdvancedSearch.vue'
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaskCampaignTypeService from '@/services/TaskCampaignTypeService'
import ContactService from '@/services/ContactService'
import CustomerService from '@/services/CustomerService'
import TaskCampaignDeliveryRestrictionService from '@/services/TaskCampaignDeliveryRestrictionService'

export default {
  name: 'TaskCampaignTypeForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.mail-group.title') + ' - %s'
    }
  },
  data () {
    return {
      formErrors: {},
      isLoading: true,
      fullPage: true,
      tipoDisparadorList: [],
      //  Selected Values (Form)
      restricao: {
        idtipoDisparador: null,
        restricaoClientes: [],
        restricaoContatos: []
      },
      tipoDisparador: {
        nome: null,
        ativo: 1,
        verificarCadencia: 1
      },
      contactList: [],
      customerList: [],
      contatosFields: [
        {
          name: 'idpessoa_contato',
          title: '#',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cliente',
          title: this.$i18n.t('form.customer.label'),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nickname',
          title: this.$i18n.t('origin_destination.nickname'),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      clientesFields: [
        {
          name: 'id',
          title: '#',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'name',
          title: this.$i18n.t('form.customer.name'),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cnpj',
          title: this.$i18n.t('form.customer.cpfCnpj'),
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      submitted: false

    }
  },
  components: {
    Loading,
    AdvancedSearch,
    AdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data

    // If is edit populate values
    let id = this.$route.params.id

    TaskCampaignTypeService.getTaskCampaignTypeList({}).then(res => {
      _this.tipoDisparadorList = res.data.data

      if (id) {
        TaskCampaignDeliveryRestrictionService.get(id).then(entity => {
          _this.restricao.idtipoDisparador = entity.data.idtipo_disparador

          // Customers
          if (entity.data.delivery_restriction_customer) {
            _this.restricao.restricaoClientes = entity.data.delivery_restriction_customer.map((item) => {
              return {
                'id': item.id_pessoa.id,
                'id_customer': item.id_pessoa.id,
                'name': item.id_pessoa.nome,
                'cnpj': item.id_pessoa.cpf_cnpj,
                'status': 1
              }
            })
          }

          // Contacts
          if (entity.data.delivery_restriction_contact) {
            _this.restricao.restricaoContatos = entity.data.delivery_restriction_contact.map((item) => {
              return {
                'id': item.idpessoa_contato.id,
                'cliente': item.idpessoa_contato.idpessoa.nome,
                'nome': item.idpessoa_contato.nome,
                'label': item.idpessoa_contato.email + item.idpessoa_contato.nome,
                'email': item.idpessoa_contato.email,
                'status': 1
              }
            })
          }
        }).finally(() => {
          _this.isLoading = false
        })
      } else {
        _this.isLoading = false
      }
    })
  },
  methods: {
    scrollToTop () {
      global.window.scrollTo(0, 0)
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TaskCampaignDeliveryRestrictionIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let restricaoData = {
            idtipoDisparador: _this.restricao.idtipoDisparador.id,
            deliveryRestrictionCustomer: _this.restricao.restricaoClientes.map((item) => {
              return {
                'idPessoa': item.id
              }
            }),
            deliveryRestrictionContact: _this.restricao.restricaoContatos.map((item) => {
              return {
                'idpessoaContato': item.id
              }
            })
          }

          let id = this.$route.params.id

          if (id) {
            TaskCampaignDeliveryRestrictionService.edit(id, restricaoData).then(response => {
              _this.$router.push({ name: 'TaskCampaignDeliveryRestrictionIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          } else {
            TaskCampaignDeliveryRestrictionService.new(restricaoData).then(response => {
              _this.$router.push({ name: 'TaskCampaignDeliveryRestrictionIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        } else {
          _this.scrollToTop()
        }
      })
    },
    setTipoDisparador (value) {
      this.restricao.idtipoDisparador = value
    },
    /**
     * Triggered when the "Tipo Disparador" search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    tipoDisparadorFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TaskCampaignTypeService.getTaskCampaignTypeList(filters).then(res => {
        this.tipoDisparadorList = res.data.data
      }).finally(() => {
        loading(false)
      })
    },
    //  Contacts
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    contactFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ContactService.getContacts(filters).then(res => {
        this.hasData = res.data.hasData
        this.contactList = res.data.data
        loading(false)
      })
    },
    contactRemoved (val) {
    },
    contactSelected (val) {
    },
    contactCustomLabel (option) {
      let label = []

      if (option['email']) {
        label.push(option['email'])
      }

      if (option['nome']) {
        label.push(option['nome'])
      }

      if (option['cliente']) {
        label.push(option['cliente'])
      }

      return label.join(' - ')
    },
    // Customers
    customerFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        this.hasData = res.data.hasData
        this.customerList = res.data.data
        loading(false)
      })
    },
    customerRemoved (val) {
    },
    customerSelected (val) {
    },
    customerCustomLabel (option) {
      let label = []

      if (option['name']) {
        label.push(option['name'])
      }

      if (option['cnpj']) {
        label.push(option['cnpj'])
      }

      return label.join(' - ')
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
